<template>
    <div class="footer">
        <div class="container">
            <li
            v-for="(item, i) in fooList" :key="i">{{item.el}}
            </li>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            fooList: [
                { el : `COPYRIGHT 2020. REDHOT CONTENTS All rights reserved.` },
            ]
        }
    }
}
</script>

<style scoped>
@media all and (min-width: 768px) {
    .footer, .container {
        width: 100vw;
        height: 56px;
        background: #ea3022;
    }
    .footer {
        display: table;
    }
    .footer .container {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
    }
    .footer .container li {
        display: inline-block;
        list-style: none;
        margin: 0 4vw;
        color: #fff;
        font-size: 0.9rem;
    }
    a:link, a:active, a:visited { color:#fff; }
}
@media all and (max-width: 767px) {
    .footer, .container {
        width: 100vw;
        height: 56px;
        background: #ea3022;
    }
    .footer {
        display: table;
    }
    .footer .container {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
    }
    .footer .container li {
        display: inline-block;
        list-style: none;
        margin: 0 6vw;
        color: #fff;
        font-size: 0.6rem;
    }
    a:link, a:active, a:visited { color:#fff; }
}
</style>
