<template>
  <div id="app">
    <global-nav></global-nav>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import GlobalNav from '@/components/GlobalNav'
import AppFooter from '@/components/AppFooter'


export default {
  components: {
    GlobalNav,
    AppFooter
  },
}
</script>

<style>
html, body {
  scrollbar-width: none;
}
</style>
