import Vue from "vue";
import Router from "vue-router";
import store from "./store"; // Vuex, store

import Meta from "vue-meta"; //홈페이지 이름 설정
Vue.use(Meta, {
  title: "REDHOT",
});

import Home from "./views/Home.vue";
Vue.use(Router);

// 관리자페이지 이전에 로그인페이지로
const requireAuth = () => (to, from, next) => {
  if (store.state.user !== undefined) {
    return next();
  }
  next("/login");
};
// vue router 스크롤 동작
const scrollBehavior = () => (to, from, savedPosition) => {
  return { x: 0, y: 0 };
};

export default new Router({
  mode: "history",
  scrollBehavior,
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "contents" */ "./views/RedhotIs.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
    // {
    //   path: '/film',
    //   name: 'film',
    //   component: () => import(/* webpackChunkName: "film" */ './views/WorkFilm.vue')
    // },
    {
      path: "/contents",
      name: "contents",
      component: () =>
        import(/* webpackChunkName: "contents" */ "./views/WorkContents.vue"),
    },
    {
      path: "/film/:id",
      name: "filmDetail",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/FilmDetail.vue"),
    },
    {
      path: "/contents/:id",
      name: "contentsDetail",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/ContentsDetail.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/Contact.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/Login.vue"),
    },
    {
      path: "/admin",
      name: "admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "./views/Admin.vue"),
      beforeEnter: requireAuth(),
    },
  ],
});
