<template>
  <div class="home">
      <div class="title-wrapper">
          <!-- <video class="title-video" poster="http://redhotcontents.com/wp-content/themes/redhot/images/screenshot.jpg" autoplay loop muted>
            <source :src="require('../assets/video/main.mp4')" type="video/mp4" />ie9, 크롬, 사파리
            <source :src="require('../assets/video/showreel.ogv')" type="video/ogg" />크롬, 오페라, 파이어폭스
          </video> -->
          <div class="scoll-down"> 
            <div class="arrow-down"/>
          </div>
      </div>
      <!-- <div class="gnb-bg"></div> -->
      <div class="contents-container">
          <div class="contents-lists">
              <div class="work-el-wrapper"
              v-for="(item, i) in allContents" :key="i"
              :style="{backgroundImage: `url(${item.thumbnail})`}"
              @click="$router.replace('/contents/' + item.uid )"
              >
                <div class="work-el-container">
                  <div class="work-info">
                      <h4>
                        {{item.title}}
                      </h4>
                      <p>
                        {{item.client}}
                      </p>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  metaInfo(){
      return {
          titleTemplate: 'REDHOT',
      }
  },
  mounted() {
    this.$store.commit('fadeIn', '.link-exp h1')
    this.$store.commit('upDown', '.arrow-down')
    this.$store.commit('loadAllContents')
  },
  data() {
    return {
      ref: this.$store.state.refContents,
    }
  },
  computed: {
    allContents(){ return this.$store.getters.allContents },
    allFilm(){ return this.$store.getters.allFilm },
  },
} 
</script>

<style scoped>
@media all and (min-width: 768px) {
  /* main color : #EC2026 */

  /* 릴영상 넣는 곳 */
  .title-wrapper {
    width: 100vw;
    height: inherit;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1;
    background: #000;
  }
  .title-wrapper video {
    width: 100vw;
    height: auto;
    opacity: 0.7;
  }
  .title-wrapper .scoll-down {
    width: 120px;
    position: absolute;
    top: 90vh;
    left: calc( 50vw - 3vw);
    transform: rotate(45deg);
    text-align: center;
  }
  .title-wrapper .arrow-down {
    box-sizing: border-box;
    height: 2vw;
    width: 2vw;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px 6px 6px 0px;
    transition: border-width 150ms ease-in-out;
  }
  /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 96px;
    background: rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    left: 0;
    z-index:1;
  }

  /* 작품들 리스트 */
  .contents-container {
    width: 100vw;
    height: inherit;
  }
  .contents-lists {
    width: 100vw;
    margin: 0 auto;
  }
  /* 작품 하나 */
  .work-el-wrapper {
    width: 33.33333vw;
    height: 18.73125vw;
    background: #efefef;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .work-el-container {
    width: 33.33333vw;
    height: 18.73125vw;
    display: table;
  }
  .work-info {
    width: 33.33333vw;
    height: 18.73125vw;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    opacity: 0;
  }
  .work-el:hover > .work-info, .work-info:hover {
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    opacity: 1;
  }
  .work-info h4, .work-info p {
    color: #fff;
  }
  .work-info h4 {
    font-size: 2rem;
    margin: 0 0 16px;
  }
  .work-info p {
    font-size: 1.4rem;
  }

}

@media all and (max-width: 767px) {
  /* main color : #EC2026 */

  /* 릴영상 넣는 곳 */
  .title-wrapper {
    width: 100vw;
    height: 736px;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1;
    background: #000;
  }
  .title-wrapper video {
    height: 736px;
    opacity: 0.7;
    margin: 0 0 0 -440px;
  }
  .title-wrapper .scoll-down {
    width: 6vh;
    height: 3vh;
    margin: -8vh auto 0;
    text-align: center;
  }
  .title-wrapper .arrow-down {
    box-sizing: border-box;
    height: 3vh;
    width: 3vh;
    transform: rotate(45deg) !important;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px 6px 6px 0px;
    transition: border-width 150ms ease-in-out;
  }
    /* gnb-bg */
  .gnb-bg {
    width: 100vw;
    height: 80px;
    background:  rgba(0, 0, 0, 0.6);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }
  /* 작품들 리스트 */
  .contents-container {
    width: 100vw;
    height: inherit;
  }
  .contents-lists {
    width: 86vw;
    margin: 0 auto;
  }
  /* 작품 하나 */
  .work-el-wrapper {
    width: 90vw;
    height: 50vw;
    margin: 2vw 1vw 1vw -2vw;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .work-el-container {
    width: 90vw;
    height: 50vw;
  }
  .work-info {
    width: 90vw;
    height: 35vw;
    padding: 15vw 0 0 0;
    text-align: center;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);

  }
  .work-info h4, .work-info p {
    color: #fff;
  }
  .work-info h4 {
    font-size: 1.4rem;
    margin: 0 0 8px;
    padding: 5vw 0 0 0;
  }
  .work-info p {
    font-size: 0.9rem;
  }

}
</style>