<template>
<div class="nav">
    <div class="nav-container">
        <div class="cell-left">
            <!-- <div class="logo" @click="$router.push('/')">
                <img src="../assets/logo.svg" alt="레드핫 컨텐츠 로고, 메인 홈으로 이동">
            </div> -->
        </div>
        <div class="cell-center"></div>
        <div class="cell-right">
            <div class="hbg-bar" @click="GnbToggle">
                <div class="hbg-bar-top"></div>
                <div class="hbg-bar-middle"></div>
                <div class="hbg-bar-bottom"></div>
            </div>
            <div class="links-wrapper">
                <div class="links-container">
                    <router-link
                    v-for="(item, i) in links" :key="i"
                    tag="div"
                    :to="item.url"
                    class="link-el"
                    >
                    {{item.name}}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="dim"></div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            links: [
                { name: 'REDHOT CONTENTS', url: '/' },
                // { name: 'ABOUT', url: '/about' },
                { name: 'WORKS', url: '/contents' },
                // { name: 'FILM', url: '/film' },
                { name: 'CONTACT', url: '/contact' },
            ]
        }
    },
    methods: {
        GnbToggle() {
            document.querySelector('.nav').classList.toggle('gnb-active');

        },
        GnbClose() {
            document.querySelector('.nav').classList.remove('gnb-active')
        },
    },
    computed: {
        RouteChange() {
            return this.$route.path
        }
    },
    watch: {
        RouteChange(n) {
            document.querySelector('.nav').classList.remove('gnb-active')
            this.$store.state.mainLogoToggle = false;
        }
    },


}
</script>

<style scoped>
@media all and (min-width: 768px) {
    /* main color : #ea3022 */
    .nav {
        width: 100vw;
        height: 80px;
        display: table;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        background: #ea3022;
    }
    .nav .nav-container {
        height: 80px;
        display: table-cell;
        vertical-align: middle;
    }
    .nav .nav-container > div {
        display: inline-block;
    }
    .nav .nav-container .cell-left {
        /* padding: 2vh 0 2vh 3vw; */
    }
    /* .nav .nav-container .cell-left .logo {
        width: 64px;
        height: 64px;
        cursor: pointer;
    } */
    .links-wrapper .links-container {
        width: 96vw;
        height: 64px;
    }
    .links-wrapper {
        display: table;
    }
    .links-container {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
    }
    .links-wrapper .links-container div{
        font-size: 1.2rem;
        font-weight: 900;
        color: #fff;
        margin: 24px 0 0 2vw;
        cursor: pointer;
        display:inline-block;
    }
    .router-link-exact-active {
        border-bottom: 4px solid #fff;
    }
    /* 햄버거바 */
    .hbg-bar {
        display: none;
    }
    /* gnb 링크들 */
    .gnb-active {
        display: none;
    }

}

@media all and (max-width: 767px) {
    /* main color : #ea3022 */
    .links-wrapper {
        display: none;
    }
    .router-link-exact-active {
        text-decoration: underline;
        text-decoration-color: #fff;
    }
    .nav {
        width: 100vw;
        height: 80px;
        display: table;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        background: #ea3022;
    }
    .nav .nav-container {
        width: 100vw;
        height: 80px;
        display: table-cell;
        vertical-align: middle;
    }
    .nav .nav-container > div {
        display: inline-block;
    }
    .nav .nav-container .cell-left {
        padding: 0 0 0 4vw;
    }
    .nav .nav-container .cell-left .logo {
        width: 6vh;
        height: 6vh;
        cursor: pointer;
    }
    /* 햄버거바 */
    .hbg-bar {
        position: fixed;
        right: 3vw;
        top: 15px;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .hbg-bar > div {
        height: 4px;
        background: #fff;
        margin: 8px 0 0 0;
    }

    .hbg-bar-top {
        width: 15px;
    }
    .hbg-bar-middle {
        width: 30px;
    }
    .hbg-bar-bottom {
        width: 15px;
        transform: translateX(15px);
    }


    /* gnb를 눌렀을 때 */
    /* 햄버거바 애니메이션 */
    .gnb-active .hbg-bar {
        z-index: 1000;
    }
    .gnb-active .hbg-bar-top {
        transition: 0.5s;
        background: #fff;
        transform: translate(12px, 8px) rotate(-45deg) scaleX(1.2);
    }
    .gnb-active .hbg-bar-middle {
        transition: 0.5s;
        background: #fff;
        transform: rotate(45deg)
    }
    .gnb-active .hbg-bar-bottom {
        transition: 0.5s;
        background: #fff;
        transform: translate(3px, -8px) rotate(-45deg) scaleX(1.1);
    }

    /* gnb 링크들 */
    .gnb-active {
        z-index: 90;
    }
    .gnb-active .logo {
        z-index: 1000;
    }
    .gnb-active .dim {
        display: block; 
        position: fixed;
        z-index: 90;
        width: 100vw;
        height: 100vh;
        top: 80px;
        left: 0;
        background: #ea3022;
    }
    .gnb-active .links-wrapper {
        width: 100vw;
        height: 100vh;
        display: table; 
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
    }
    .gnb-active .links-wrapper .links-container {
        width: 30vw;
        height: 100vh;
        padding: 90px 6vw 0 0;
        display: table-cell; 
        text-align: right;
        font-size: 2rem;
        font-weight: 900;
    }
    .gnb-active .link-el {
        display: block;
        color: #fff;
        margin: 32px 0 0 0;
    }
    .gnb-active .link-el:hover {
        transition: 0.3s;
    }

}
</style>
