import Vue from "vue";

import Vuex from "vuex";
Vue.use(Vuex);

// 파이어베이스
import firebase from "firebase"; // firebase
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/database");

var firebaseConfig = {
  apiKey: "AIzaSyCRvrg5jL1OW4wwLHryebvlKHswSp6kcK8",
  authDomain: "redhot-contents.firebaseapp.com",
  databaseURL: "https://redhot-contents.firebaseio.com",
  projectId: "redhot-contents",
  storageBucket: "redhot-contents.appspot.com",
  messagingSenderId: "911735357200",
  appId: "1:911735357200:web:f0c6c6ee4dd039a5",
};
firebase.initializeApp(firebaseConfig);

// 비메오 앱
let Vimeo = require("vimeo").Vimeo;

// anime js
import anime from "animejs";

export default new Vuex.Store({
  state: {
    user: undefined,
    // 모든 Works 가져오기
    allData: [],
    allContents: [],
    allFilm: [],

    refContents: firebase.firestore().collection("Contents"),
    refFilm: firebase.firestore().collection("Film"),

    refContentsOrderd: firebase
      .firestore()
      .collection("Contents")
      .orderBy("createdAt", "desc"),
    refFilmOrderd: firebase
      .firestore()
      .collection("Film")
      .orderBy("createdAt", "desc"),

    storage: firebase.storage(), // 기본값은 ()로 비움, 특정 저장소를 지정하거나 여러저장소 쓸때 주소를 써준다
    storageRef: firebase.storage().ref(),

    mainLogoToggle: true,
  },
  getters: {
    allContents(s, p) {
      return s.allContents;
    },
    allFilm(s, p) {
      return s.allFilm;
    },
    isLogin(s, p) {
      return s.user;
    },
  },

  mutations: {
    loadAllFilm(s, p) {
      s.refFilmOrderd.get().then(function(snapshot) {
        let array = [];
        snapshot.forEach(function(doc) {
          const item = doc.data();
          item._id = doc._id;
          array.push(item);
        });
        s.allFilm = array.sort(function(a, b) {
          return b.createdAt - a.createdAt;
        });
      });
    },
    loadAllContents(s, p) {
      s.refContentsOrderd.get().then(function(snapshot) {
        let array = [];
        snapshot.forEach(function(doc) {
          const item = doc.data();
          item._id = doc._id;
          array.push(item);
        });
        s.allContents = array.sort(function(a, b) {
          return b.createdAt - a.createdAt;
        });
      });
    },
    setUser(s, payload) {
      s.user = payload;
    },
    //애니메이션
    logoAnime(s, p) {
      anime({
        targets: p,
        translateX: "102rem",
        translateY: "-11.13rem",
        duration: 1000,
        delay: 400,
        easing: "easeInOutExpo",
      });
    },
    logoAnime2(s, p) {
      anime({
        targets: p,
        translateX: "-86.38rem",
        translateY: "11.13rem",
        duration: 1000,
        delay: 480,
        easing: "easeInOutExpo",
      });
    },
    fadeIn(s, p) {
      anime({
        targets: p,
        delay: 200,
        opacity: 1,
        duration: 8000,
      });
    },
    upDown(s, p) {
      anime({
        targets: p,
        translateX: 12,
        translateY: 12,
        duration: 900,
        direction: "alternate",
        loop: true,
      });
    },
  },
  actions: {
    login({ commit }, payload) {
      return firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.pwd)
        .then((r) => {
          // Login complete here.
          commit("setUser", r.user);
          return r.user;
        })
        .catch(function(error) {
          console.log(error.message);
        });
    },
  },
});
